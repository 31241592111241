import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import { SentimentVeryDissatisfied, SentimentVerySatisfied } from "@mui/icons-material";
import FeedbackDialog from "./FeedbackDialog";
import { addFeedback } from "../../../api/orderApi";
import { FeedbackType } from "../../../api/gen";
import { useTranslation } from "react-i18next";
import "../../../i18n";

const FeedbackButton = ({ orderId, storePhoneNumber, storeEmail, onFeedbackReceived }: FeedbackButtonPropTypes) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleFeedbackReceived = (feedbackType: FeedbackType, comments: string | null) => {
    addFeedback(orderId, feedbackType, comments || "").then(() => {
      onFeedbackReceived && onFeedbackReceived(feedbackType);
    }); // just let it run async. If it doesn't work then don't worry about it. Don't hold up the UI thread waiting for it to save.
    setOpen(false);
  };

  return (
    <>
      <FeedbackDialog open={open} onClose={() => setOpen(false)} onSave={handleFeedbackReceived} storePhoneNumber={storePhoneNumber} storeEmail={storeEmail} />

      <Paper elevation={1} sx={{ p: 2, width: "100%", backgroundColor: "secondary.main", color: "white" }}>
        <Box display={"flex"} justifyContent={"space-between"} width={"100%"} alignItems={"center"} onClick={() => setOpen(true)}>
          {t("Rate-Your-Experience")}
          <div>
            <SentimentVeryDissatisfied sx={{ pr: 1 }} />
            <SentimentVerySatisfied sx={{ pl: 1 }} />
          </div>
        </Box>
      </Paper>
    </>
  );
};

type FeedbackButtonPropTypes = {
  orderId: string;
  storePhoneNumber?: string;
  storeEmail?: string;
  onFeedbackReceived: (type: FeedbackType) => void;
};

export default FeedbackButton;
