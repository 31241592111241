import React from "react";
import { Box, Typography } from "@mui/material";
import { DigitalWalletType } from "../../../models";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { TFunction } from "i18next";

type FancyWalletLabelPropTypes = {
  walletType?: DigitalWalletType;
};

const createDigitalWalletLabel = (t: TFunction, walletType?: DigitalWalletType) => {
  if (walletType === DigitalWalletType.APPLE_PAY) return "Apple Pay";
  if (walletType === DigitalWalletType.GOOGLE_PAY) return "Google Pay";
  return t("Detecting");
};

const FancyWalletLabel = ({ walletType }: FancyWalletLabelPropTypes) => {
  const { t } = useTranslation();

  return (
    <Box display={"flex"} alignItems={"center"} columnGap={2}>
      {/*{iconMap.get(walletType)} <-- looks a bit busy*/}
      <Typography>{createDigitalWalletLabel(t, walletType)}</Typography>
    </Box>
  );
};

export default FancyWalletLabel;
