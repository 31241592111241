import React from "react";
import { Box, Typography } from "@mui/material";
import CardIconSelector from "../assets/cardicons/CardIconSelector";
import { CardScheme } from "../../../api/gen";

type FancyCardLabelPropTypes = {
  scheme: CardScheme;
  last4: string;
};

const FancyCardLabel = ({ scheme, last4 }: FancyCardLabelPropTypes) => (
  <Box display={"flex"} alignItems={"center"} columnGap={2}>
    <CardIconSelector cardType={scheme} />
    <Typography>•••• {last4}</Typography>
  </Box>
);
export default FancyCardLabel;
