/* tslint:disable */
/* eslint-disable */
/**
 * My Title
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const MachineType = {
    Washer: 'Washer',
    Dryer: 'Dryer',
    Collection: 'Collection',
    Vending: 'Vending',
    MassageChair: 'MassageChair',
    ServicePay: 'ServicePay',
    TokenMachine: 'TokenMachine',
    SoapDispenser: 'SoapDispenser',
    Shower: 'Shower',
    ArcadeMachine: 'ArcadeMachine',
    GameMachine: 'GameMachine',
    MilkDispenser: 'MilkDispenser'
} as const;
export type MachineType = typeof MachineType[keyof typeof MachineType];


export function instanceOfMachineType(value: any): boolean {
    for (const key in MachineType) {
        if (Object.prototype.hasOwnProperty.call(MachineType, key)) {
            if ((MachineType as Record<string, MachineType>)[key] === value) {
                return true;
            }
        }
    }
    return false;
}

export function MachineTypeFromJSON(json: any): MachineType {
    return MachineTypeFromJSONTyped(json, false);
}

export function MachineTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineType {
    return json as MachineType;
}

export function MachineTypeToJSON(value?: MachineType | null): any {
    return value as any;
}

