import React, { useState } from "react";
import { Box, Button, Collapse, Link, TextField, Typography } from "@mui/material";
import { SentimentVeryDissatisfied, SentimentVerySatisfied } from "@mui/icons-material";
import DialogWithTitle from "../../common/dialog/DialogWithTitle";
import ReactGA from "react-ga4";
import { FeedbackType } from "../../../api/gen";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { TFunction } from "i18next";

const NegativeContent = ({ phoneNumber, emailAddress, t }: { phoneNumber?: string; emailAddress?: string; t: TFunction }) => (
  <>
    <Typography variant={"h6"} component={"div"} sx={{ mx: 2, textAlign: "center", mb: 2 }}>
      {t("Feedback-Question-2")}
    </Typography>
    <Typography variant={"caption"} component={"div"} sx={{ paddingBottom: 2 }}>
      {t("Feedback-Message-2")}
    </Typography>
    <Typography variant={"caption"} component={"div"}>
      {t("Feedback-Message-3")}
      {phoneNumber && (
        <>
          {t("Calling")} <Link href={"tel:" + phoneNumber}>{phoneNumber}</Link> {t("Or")}
        </>
      )}
      {t("Email").toLowerCase()} <Link href={"mailto:" + emailAddress}>{emailAddress}.</Link>
    </Typography>
  </>
);

const PositiveContent = ({ t }: { t: TFunction }) => (
  <>
    <Typography variant={"h6"} component={"div"} sx={{ mx: 2, textAlign: "center", mb: 2 }}>
      {t("Feedback-Thanks")}
    </Typography>
  </>
);

const FeedbackDialog = ({ onClose, onSave, open, storeEmail, storePhoneNumber }: FeedbackDialogPropTypes) => {
  const [feedbackType, setFeedbackType] = useState(null as FeedbackType | null);
  const [comments, setComments] = useState("");
  const { t } = useTranslation();

  const handleFeedbackButtonClicked = (feedbackType: FeedbackType) => {
    setFeedbackType(feedbackType);
  };

  if (open) {
    ReactGA.event({
      category: "Receipt",
      action: "Feedback opened",
    });
  }

  return (
    <DialogWithTitle open={open} onClose={onClose} title={t("Feedback")}>
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant={"h6"} component={"div"} sx={{ m: 2, textAlign: "center", pb: 4 }}>
          {t("Feedback-Question-1")}
        </Typography>
        <Box display={"flex"} justifyContent={"space-between"} mx={4}>
          <Typography variant={"h1"} component={"div"} color={"grey"}>
            <SentimentVeryDissatisfied
              sx={{ fontSize: 100, color: feedbackType === FeedbackType.Negative ? "red" : "lightgray" }}
              onClick={() => handleFeedbackButtonClicked(FeedbackType.Negative)}
            />
          </Typography>
          <Typography variant={"h1"} component={"div"} color={"grey"}>
            <SentimentVerySatisfied
              sx={{ fontSize: 100, color: feedbackType === FeedbackType.Positive ? "green" : "lightgray" }}
              onClick={() => handleFeedbackButtonClicked(FeedbackType.Positive)}
            />
          </Typography>
        </Box>
      </Box>

      <Collapse in={!!feedbackType}>
        <Box px={2}>
          {feedbackType === FeedbackType.Positive && <PositiveContent t={t} />}
          {feedbackType === FeedbackType.Negative && <NegativeContent emailAddress={storeEmail} phoneNumber={storePhoneNumber} t={t} />}
        </Box>

        <Box m={2} display={"flex"} flexDirection={"column"}>
          <TextField multiline maxRows={4} value={comments} onChange={(e) => setComments(e.target.value)} label={t("Additional-Comments")} />
          <Typography variant={"caption"} component={"div"} sx={{ mt: 1 }}>
            {t("Feedback-Message-1")}
          </Typography>
        </Box>
        <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"} mx={2}>
          <Button variant={"text"} color={"primary"} onClick={() => onSave(feedbackType!, null)}>
            {t("No-Thanks")}
          </Button>
          <Button variant={"contained"} color={"primary"} onClick={() => onSave(feedbackType!, comments)}>
            {t("Save-And-Close")}
          </Button>
        </Box>
      </Collapse>
    </DialogWithTitle>
  );
};

type FeedbackDialogPropTypes = {
  onClose: () => void;
  onSave: (feedbackType: FeedbackType, comments: string | null) => void;
  open: boolean;
  storePhoneNumber?: string;
  storeEmail?: string;
};

export default FeedbackDialog;
