import React from "react";
import { SvgIcon } from "@mui/material";
import { CreditCardRounded } from "@mui/icons-material";
import { ReactComponent as VisaIcon } from "./Visa-Logo.wine.svg";
import { ReactComponent as MastercardIcon } from "./Mastercard-Logo.wine.svg";
import { ReactComponent as AmexIcon } from "./American_Express-Logo.wine.svg";
import { CardScheme } from "../../../../api/gen";

const cardIcons = new Map([
  [
    "mastercard",
    <SvgIcon sx={{ transform: "scale(1.5)" }} viewBox={"0 0 48 48"} aria-label={"Mastercard"}>
      <MastercardIcon width={48} height={48} scale={2} />
    </SvgIcon>
  ],
  [
    "visa",
    <SvgIcon sx={{ transform: "scale(1.5)" }} viewBox={"0 0 48 48"} aria-label={"Visa"}>
      <VisaIcon width={48} height={48} scale={2} />
    </SvgIcon>
  ],
  [
    "amex",
    <SvgIcon sx={{ transform: "scale(1.5)" }} viewBox={"0 0 48 48"} aria-label={"American Express"}>
      <AmexIcon width={48} height={48} scale={2} />
    </SvgIcon>
  ]
]);

type CardIconSelectorPropTypes = {
  cardType: string | CardScheme | undefined;
};

const CardIconSelector = ({ cardType }: CardIconSelectorPropTypes) => {
  if (cardType && cardIcons.has(cardType.toLowerCase())) {
    return cardIcons.get(cardType.toLowerCase())!;
  }

  return <CreditCardRounded />;
};

export default CardIconSelector;
