import { Alert, AlertTitle, Box, Button, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import React, { useState } from "react";
import ChevronButton from "../../common/controls/ChevronButton";
import DialogWithTitle from "../../common/dialog/DialogWithTitle";
import ReactGA from "react-ga4";
import { isIOS, isMobileSafari, isSafari } from "react-device-detect";
import { DigitalWalletType, PaymentOption } from "../../../models";
import { CardOnFileResponse } from "../../../api/gen";
import RememberedCardLabel from "./RememberedCardLabel";
import FancyCardLabel from "./FancyCardLabel";
import FancyWalletLabel from "./FancyWalletLabel";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CardAcceptanceMarks } from "../components/common/CardAcceptanceMarks";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import { TFunction } from "i18next";

const createLabel = (t: TFunction, paymentOption: PaymentOption, digitalWalletType?: DigitalWalletType, cardOnFile?: CardOnFileResponse) => {
  if (paymentOption === PaymentOption.CARD) return t("Card");
  if (paymentOption === PaymentOption.PAYPAL) return "PayPal";
  if (paymentOption === PaymentOption.DIGITAL_WALLET) return <FancyWalletLabel walletType={digitalWalletType!} />;
  if (paymentOption === PaymentOption.CARD_ON_FILE) return <FancyCardLabel scheme={cardOnFile?.scheme!} last4={cardOnFile?.last4!} />;
  return "";
};

const isPaymentOptionAvailable = (option: PaymentOption, paymentOptions: Array<PaymentOption>) => paymentOptions.indexOf(option) >= 0;

const PaymentTypeSelectButton: React.FC<{
  label: string;
  onChange: (paymentOption: PaymentOption) => void;
  selectedPaymentOption: PaymentOption;
  digitalWalletType?: DigitalWalletType;
  availablePaymentOptions: PaymentOption[];
  onDeleteSavedCardOnFile: () => void;
  isBusy: boolean;
  cardOnFileData?: CardOnFileResponse;
}> = (props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCancel = () => {
    handleClose();
  };

  const handleSave = (paymentOption: PaymentOption) => {
    ReactGA.event({
      category: "Checkout",
      action: `Change payment method to ${paymentOption}`,
    });
    props.onChange(paymentOption);
  };

  const handleForgetMyCard = async () => {
    props.onDeleteSavedCardOnFile();
  };

  if (open) {
    ReactGA.event({
      category: "Checkout",
      action: "Open payment method",
    });
  }

  const showWhyIsApplePayMissingPanel = (isIOS || isMobileSafari || isSafari) && props.digitalWalletType !== DigitalWalletType.APPLE_PAY;

  return (
    <>
      {/*Only make the change button prominent if PayPal is not available. We prefer not to encourage PayPal.*/}
      <Box p={2}>
        <ChevronButton
          onClick={handleClickOpen}
          label={props.label}
          labelStyle={{ minWidth: "75px", fontWeight: "bold" }}
          secondaryLabel={createLabel(t, props.selectedPaymentOption, props.digitalWalletType, props.cardOnFileData)}
          icon={<ArrowDropDownIcon />}
        />
      </Box>

      <DialogWithTitle open={open} onClose={handleCancel} title={t("Select-Payment-Type")}>
        <Box p={2} pt={4} display="flex" flexDirection="column">
          <Box mb={4} display="flex" flexDirection="column">
            <FormControl component="fieldset" sx={{ mb: 3 }}>
              <RadioGroup
                aria-label="paymentMethod"
                name="paymentMethod"
                value={props.selectedPaymentOption || props.selectedPaymentOption}
                onChange={(e: any) => handleSave(e.target.value)}
              >
                {isPaymentOptionAvailable(PaymentOption.CARD_ON_FILE, props.availablePaymentOptions) && props.cardOnFileData && (
                  <FormControlLabel
                    key={PaymentOption.CARD_ON_FILE}
                    value={PaymentOption.CARD_ON_FILE}
                    control={<Radio />}
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        width: "100%",
                      },
                    }}
                    label={
                      <RememberedCardLabel
                        scheme={props.cardOnFileData.scheme}
                        last4={props.cardOnFileData.last4}
                        expireMonth={props.cardOnFileData.expireMonth}
                        expireYear={props.cardOnFileData.expireYear}
                        onDelete={handleForgetMyCard}
                        busy={props.isBusy}
                      />
                    }
                  />
                )}
                {isPaymentOptionAvailable(PaymentOption.DIGITAL_WALLET, props.availablePaymentOptions) && (
                  <FormControlLabel
                    key={PaymentOption.DIGITAL_WALLET}
                    value={PaymentOption.DIGITAL_WALLET}
                    control={<Radio />}
                    label={<FancyWalletLabel walletType={props.digitalWalletType} />}
                  />
                )}
                {isPaymentOptionAvailable(PaymentOption.CARD, props.availablePaymentOptions) && (
                  <FormControlLabel key={PaymentOption.CARD} value={PaymentOption.CARD} control={<Radio />} label={t("Card")} />
                )}
                {isPaymentOptionAvailable(PaymentOption.PAYPAL, props.availablePaymentOptions) && (
                  <FormControlLabel key={PaymentOption.PAYPAL} value={PaymentOption.PAYPAL} control={<Radio />} label={"PayPal"} />
                )}
              </RadioGroup>
            </FormControl>
            <Button variant="contained" color="secondary" onClick={handleClose} sx={{ mt: 4 }}>
              {t("Save")}
            </Button>
          </Box>

          <CardAcceptanceMarks />

          {showWhyIsApplePayMissingPanel && (
            <>
              <Divider sx={{ mt: 2 }} />
              <Box pt={4}>
                <Alert severity={"info"} variant={"outlined"}>
                  <AlertTitle>{t("Apple-Pay-Alert-Title")}</AlertTitle>

                  {(isSafari || isMobileSafari) && (
                    <Typography variant="caption" component={"div"}>
                      {t("Apple-Pay-Alert-Message-1")}
                      <ul style={{ paddingLeft: 16 }}>
                        <li>{t("Apple-Pay-Alert-Reminder-1")}</li>
                        <li>{t("Apple-Pay-Alert-Reminder-2")}</li>
                        <li>{t("Apple-Pay-Alert-Reminder-3")}</li>
                      </ul>
                      {t("Apple-Pay-Alert-Message-2")}
                    </Typography>
                  )}
                  {!(isSafari || isMobileSafari) && (
                    <>
                      <Typography variant="caption">{t("Apple-Pay-Alert-Message-3")}</Typography>
                      <Typography variant="caption" component={"div"} sx={{ mt: 1 }}>
                        {t("Apple-Pay-Alert-Message-4")}
                      </Typography>
                    </>
                  )}
                </Alert>
              </Box>
            </>
          )}
        </Box>
      </DialogWithTitle>
    </>
  );
};

export default PaymentTypeSelectButton;
