import React, { useEffect, useState } from "react";
import { Alert, AlertTitle, Box, Paper, Typography } from "@mui/material";
import BusyButton from "../../common/controls/BusyButton";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import { LocalSettingsService } from "../../../service/localSettingsService";
import PayPalButton from "../components/paypal/PayPalButton";
import PaymentTypeSelectButton from "./PaymentTypeSelectButton";
import { PaymentTokenType, SiteStatus } from "../../../api/gen";
import { usePaymentMethods, useSiteConfig } from "../../../hooks";
import { DigitalWalletType, MachineCredit, PaymentOption } from "../../../models";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import DigitalWalletEntry from "../components/DigitalWalletEntry";
import CardEntry from "../components/CardEntry";
import CardOnFile from "../components/CardOnFile";
import { DigitalWalletTypeChangedEvent, TokenReceivedCallback } from "../CheckoutPage";
import { setUserProfile } from "../../../redux/actions/userProfileActions";
import { deleteCardFromProfile } from "../../../api/userProfileApi";
import { useTranslation } from "react-i18next";
import "../../../i18n";

/**
 * @param {string} storeName
 * @param {MachineCredit[]} machineCredits
 */
const makeDescription = (storeName: string, machineCredits: Array<MachineCredit>) => {
  if (!storeName || !machineCredits) return "";
  const machineNames = machineCredits.map((mc) => mc.machine.name).join(", ");
  return `${storeName}, ${machineNames}`;
};

const selectDefaultPaymentOption = (paymentMethods: Map<PaymentOption, boolean>) => {
  // Card on file always wins, if it's available. Card on file works only if card entry is also available
  if (paymentMethods.get(PaymentOption.CARD_ON_FILE)) return PaymentOption.CARD_ON_FILE;
  if (paymentMethods.get(PaymentOption.DIGITAL_WALLET)) return PaymentOption.DIGITAL_WALLET;
  if (paymentMethods.get(PaymentOption.CARD)) return PaymentOption.CARD;
  if (paymentMethods.get(PaymentOption.PAYPAL)) return PaymentOption.PAYPAL;
  return null;
};

const PaymentNotRequiredPanel = () => {
  const { t } = useTranslation();

  return (
    <Alert severity={"success"} variant={"outlined"} sx={{ my: 2 }}>
      {t("Payment-Not-Required")}
    </Alert>
  );
};

const TrialModePanel = () => {
  const { t } = useTranslation();

  return (
    <Alert severity={"warning"} variant={"outlined"} sx={{ my: 2 }}>
      {t("Trial-Mode-Message")}
    </Alert>
  );
};

const NoPaymentMethodsPanel = () => {
  const { t } = useTranslation();

  return (
    <Alert severity={"warning"} variant={"standard"} sx={{ my: 2 }}>
      <AlertTitle>{t("Payment-Not-Supported")}</AlertTitle>
      {t("Payment-Not-Supported-Message")}
    </Alert>
  );
};

const PaymentPart = ({ onPaymentTokenReceived, onPaymentOptionChanged, digitalWalletType, onDigitalWalletChanged }: PaymentPartPropTypes) => {
  const quote = useAppSelector((state) => state.quote?.quote);
  const cart = useAppSelector((state) => state.cart);
  const { userProfile } = useAppSelector((state) => state.userProfile);
  const { siteConfig } = useSiteConfig();
  const paymentMethods = usePaymentMethods();
  const dispatch = useAppDispatch();
  const [selectedPaymentOption, setSelectedPaymentOption] = useState<PaymentOption | null>(null);
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState<Map<PaymentOption, boolean>>(
    () =>
      new Map([
        [PaymentOption.PAYPAL, !!paymentMethods.payPalChannel],
        [PaymentOption.DIGITAL_WALLET, !!paymentMethods.digitalWalletChannel],
        [PaymentOption.CARD, !!paymentMethods.cardChannel],
        [PaymentOption.CARD_ON_FILE, !!paymentMethods.cardChannel],
      ])
  );
  const [showBusyPaymentButton, setShowBusyPaymentButton] = useState(false);
  const { t } = useTranslation();

  // This sets the default payment method to be the "first" if it is not already set. If the user had a preference that is no longer available, it is reset.
  // In a useEffect because the paymentMethods build up over time as they are discovered. It can run many times until it stabilises.
  useEffect(() => {
    // Don't do anything here until all payment methods have been checked, otherwise it causes multiple quote requests because the cart keeps updating
    if (!siteConfig) {
      return;
    }

    const preferredPaymentOption = LocalSettingsService.forSite(siteConfig.siteId).preferredPaymentOption;

    // There is no preference, or the preference no longer exists as an option
    if (preferredPaymentOption && availablePaymentOptions.get(preferredPaymentOption)) {
      console.debug(`Using saved payment option as the default - ${preferredPaymentOption}`);
      setSelectedPaymentOption(preferredPaymentOption);
      onPaymentOptionChanged && onPaymentOptionChanged(preferredPaymentOption);
    } else {
      const defaultPaymentOption = selectDefaultPaymentOption(availablePaymentOptions);
      console.debug(`Default payment option is ${defaultPaymentOption}`);
      if (defaultPaymentOption) {
        setSelectedPaymentOption(defaultPaymentOption);
        onPaymentOptionChanged && onPaymentOptionChanged(defaultPaymentOption);
      }
    }
  }, [availablePaymentOptions, onPaymentOptionChanged, siteConfig]);

  const description = makeDescription(siteConfig?.name ?? "", cart.machineCredits);

  const handlePaymentMethodChange = (paymentOption: PaymentOption) => {
    LocalSettingsService.setPreferredPaymentMethod(paymentOption);
    setSelectedPaymentOption(paymentOption);
    onPaymentOptionChanged && onPaymentOptionChanged(paymentOption);
  };

  const handleDeleteSavedCardOnFile = async () => {
    const cardId = userProfile?.cardsOnFile?.[0].id;
    setShowBusyPaymentButton(true);
    // FIXME shouldn't this be in redux?
    const result = await deleteCardFromProfile(LocalSettingsService.getId()!, cardId!);
    setShowBusyPaymentButton(false);
    LocalSettingsService.setPreferredPaymentMethod(null);

    if (selectedPaymentOption === PaymentOption.CARD_ON_FILE) {
      setSelectedPaymentOption(null);
    }
    dispatch(setUserProfile(result));
  };

  const handleCardInitialised = (isMounted: boolean) => {
    console.log(`Set card available to ${isMounted}`);
    if (availablePaymentOptions.get(PaymentOption.CARD_ON_FILE) === isMounted) {
      return;
    }
    const newPaymentOptions = new Map(availablePaymentOptions);
    newPaymentOptions.set(PaymentOption.CARD, isMounted);
    setAvailablePaymentOptions(newPaymentOptions);
  };

  const handlePayPalInitialised = () => {
    console.log(`Set PayPal available to true`);
    const newPaymentOptions = new Map(availablePaymentOptions);
    newPaymentOptions.set(PaymentOption.PAYPAL, true);
    setAvailablePaymentOptions(newPaymentOptions);
  };

  const handleCardOnFileInitialised = (isMounted: boolean) => {
    console.log(`Set card on file available to ${isMounted}`);
    if (availablePaymentOptions.get(PaymentOption.CARD_ON_FILE) === isMounted) {
      return;
    }
    const newPaymentOptions = new Map(availablePaymentOptions);
    newPaymentOptions.set(PaymentOption.CARD_ON_FILE, isMounted);
    setAvailablePaymentOptions(newPaymentOptions);
  };

  const requirePayment = (quote?.amounts?.grandTotal || 0) > 0 && siteConfig?.status === SiteStatus.Active;
  return (
    <div>
      <Typography variant="h5" component="div" mb={2}>
        {t("Payment")}
      </Typography>

      {requirePayment && quote && (
        <>
          {paymentMethods.isAnyMethodAvailable && selectedPaymentOption && (
            <Box py={1} mt={1} mb={2}>
              <Paper elevation={1}>
                <PaymentTypeSelectButton
                  label={t("Pay-With")}
                  selectedPaymentOption={selectedPaymentOption}
                  onChange={handlePaymentMethodChange}
                  availablePaymentOptions={Array.from(availablePaymentOptions.entries())
                    .filter((e) => e[1])
                    .map((e) => e[0])}
                  isBusy={showBusyPaymentButton}
                  digitalWalletType={digitalWalletType}
                  cardOnFileData={userProfile?.cardsOnFile?.[0]}
                  onDeleteSavedCardOnFile={handleDeleteSavedCardOnFile}
                />
              </Paper>
            </Box>
          )}

          {!paymentMethods.isAnyMethodAvailable && NoPaymentMethodsPanel}

          {paymentMethods.payPalChannel && (
            <PayPalButton
              onTokenReceived={onPaymentTokenReceived}
              amount={quote?.amounts.grandTotal ?? 0}
              description={description}
              visible={selectedPaymentOption === PaymentOption.PAYPAL}
              onInit={handlePayPalInitialised}
            />
          )}
          {paymentMethods.digitalWalletChannel && digitalWalletType && (
            <DigitalWalletEntry
              onTokenReceived={onPaymentTokenReceived}
              visible={selectedPaymentOption === PaymentOption.DIGITAL_WALLET}
              amount={quote.amounts.grandTotal}
              onDigitalWalletChanged={onDigitalWalletChanged}
              digitalWalletType={digitalWalletType}
            />
          )}
          {paymentMethods.cardChannel && (
            <>
              <CardEntry
                onTokenReceived={onPaymentTokenReceived}
                visible={selectedPaymentOption === PaymentOption.CARD}
                description={description}
                onInit={handleCardInitialised}
                cart={cart}
                amounts={quote?.amounts}
                disableSaveMyCard={(userProfile?.cardsOnFile?.length ?? 0) > 0}
                initialEmailAddress={userProfile?.emailAddress}
              />
              <CardOnFile
                onTokenReceived={onPaymentTokenReceived}
                visible={selectedPaymentOption === PaymentOption.CARD_ON_FILE}
                onInit={handleCardOnFileInitialised}
                amount={quote.amounts.grandTotal}
                userProfile={userProfile}
              />
            </>
          )}
        </>
      )}
      {!requirePayment && (
        <>
          {siteConfig?.status === SiteStatus.Trial && TrialModePanel}
          {siteConfig?.status !== SiteStatus.Trial && PaymentNotRequiredPanel}

          <BusyButton
            onClick={() => onPaymentTokenReceived({ token: "NotRequired", tokenMeta: { tokenType: PaymentTokenType.PaymentBypass } })}
            icon={<PlayCircleFilledIcon />}
            busy={false}
          >
            {t("Start")}
          </BusyButton>
        </>
      )}
    </div>
  );
};

type PaymentPartPropTypes = {
  onPaymentTokenReceived: TokenReceivedCallback;
  onPaymentOptionChanged?: (paymentOption: PaymentOption) => void;
  onDigitalWalletChanged: DigitalWalletTypeChangedEvent;
  digitalWalletType?: DigitalWalletType;
};

export default PaymentPart;
